exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-go-adfree-js": () => import("./../../../src/pages/go-adfree.js" /* webpackChunkName: "component---src-pages-go-adfree-js" */),
  "component---src-pages-howtovideos-js": () => import("./../../../src/pages/howtovideos.js" /* webpackChunkName: "component---src-pages-howtovideos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-success-js": () => import("./../../../src/pages/order-success.js" /* webpackChunkName: "component---src-pages-order-success-js" */),
  "component---src-pages-reseller-js": () => import("./../../../src/pages/reseller.js" /* webpackChunkName: "component---src-pages-reseller-js" */),
  "component---src-pages-warranty-js": () => import("./../../../src/pages/warranty.js" /* webpackChunkName: "component---src-pages-warranty-js" */)
}

